import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Grid, Typography, Radio, FormControlLabel, Button, RadioGroup } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { AddressForm } from 'components/Forms';
import FlowControl from 'components/FlowControl';

import styles from './index.style';

const initialValues = {
  addressType: 'commercial',
  zipcode: '',
  city: '',
  complement: '',
  country: 'Brasil',
  neighborhood: '',
  number: '',
  state: '',
  street: '',
};

const RegisterAddressTwoColumnForm = ({ classes, onNext, onBack, onSubmit, data }) => {
  const { t } = useTranslation(['register', 'feedbacks', 'common']);

  const validate = async () => {
    document.querySelector('#submit').click();
    // setError(true)
    onNext(() => false);
  };

  return (
    <Grid container direction="row" spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h5">{t('address')}</Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '16px' }}>
          <Typography variant="subtitle2">{t('explainAddressRegister')}</Typography>
        </Grid>
        <Grid container spacing={0} alignItems="center" style={{ marginTop: '40px' }}>
          <AddressForm
            color="secondary"
            style={{ maxWidth: '406px' }}
            onSubmit={onSubmit}
            initialValues={data || initialValues}
            Footer={({ onChangeField, formValues, fieldsRegister }) => (
              <>
                <Grid item xs={12}>
                  <RadioGroup
                    style={{ flexDirection: 'row' }}
                    name="address_type"
                    value={formValues.addressType}
                    onChange={e => {
                      onChangeField({ ...formValues, addressType: e.target.value });
                    }}
                  >
                    <FormControlLabel
                      value="commercial"
                      control={
                        <Radio color="secondary" inputRef={fieldsRegister({ required: false })} />
                      }
                      name="commercial"
                      label={t('commercialAddressType')}
                    />
                    <FormControlLabel
                      value="residential"
                      control={
                        <Radio color="secondary" inputRef={fieldsRegister({ required: false })} />
                      }
                      name="residential"
                      label={t('residentialAddressType')}
                    />
                  </RadioGroup>
                </Grid>
                <Button
                  id="submit"
                  style={{ display: 'none' }}
                  className={classes.formButton}
                  type="submit"
                >
                  {t('common:register')}
                </Button>
              </>
            )}
          />
        </Grid>
        <FlowControl isSubmit={false} onBack={() => onBack(() => true)} onNext={() => validate()} />
      </Grid>
  );
};

RegisterAddressTwoColumnForm.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

RegisterAddressTwoColumnForm.defaultProps = {
  data: undefined,
};

export default withStyles(styles)(RegisterAddressTwoColumnForm);
