const styles = theme => ({
  title: {
    margin: theme.spacing(0, 0, 1, 0),
  },
  joinTitle: {
    paddingBottom: theme.spacing(2),
  },
  formButton: {
    margin: theme.spacing(3, 0, 0, 0),
    // padding: theme.spacing(2, 0),
  },
  doItLaterButton: {
    width: '100%',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  doItLaterText: {
    color: 'hsla(0, 0%, 65%, 1)',
    textAlign: 'center',
  },
  labels: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(12),
      },
    },
  },
});

export default styles;
