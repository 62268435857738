import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Grid, Typography, Radio, FormControlLabel, RadioGroup } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AddressForm } from 'components/Forms';
import { Button } from '@lc/legacy/src/Components';
import { session } from 'helpers';
import styles from './index.style';

const initialValues = {
  addressType: 'commercial',
  zipcode: '',
  city: '',
  complement: '',
  country: 'Brasil',
  neighborhood: '',
  number: '',
  state: '',
  street: '',
};

const RegisterAddressOneColumnForm = ({ classes, onNext, onSubmit, isFetching }) => {
  const { t } = useTranslation(['register', 'common']);
  const xsSize = useMediaQuery(useTheme().breakpoints.down('xs'));

  return (
    <>
      <Typography
        variant={xsSize ? 'body1' : 'h6'}
        align="center"
        component="h3"
        className={classes.title}
      >
        <strong>{t('registerAddress')}</strong>
      </Typography>
      <AddressForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        Footer={({ onChangeField, formValues, fieldsRegister }) => (
          <Grid container spacing={0}>
            <Grid item xs={12} className={classes.labels}>
              <RadioGroup
                style={{ flexDirection: 'row' }}
                name="address_type"
                value={formValues.addressType}
                onChange={e => {
                  onChangeField({ ...formValues, addressType: e.target.value });
                }}
              >
                <FormControlLabel
                  value="commercial"
                  control={
                    <Radio
                      color="primary"
                      size={xsSize ? 'small' : 'medium'}
                      inputRef={fieldsRegister({ required: false })}
                    />
                  }
                  name="commercial"
                  label={t('commercialAddressType')}
                />
                <FormControlLabel
                  value="residential"
                  control={
                    <Radio
                      color="primary"
                      size={xsSize ? 'small' : 'medium'}
                      inputRef={fieldsRegister({ required: false })}
                    />
                  }
                  name="residential"
                  label={t('residentialAddressType')}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.formButton}>
                <Button
                  loading={isFetching}
                  variant="contained"
                  color="primary"
                  size={xsSize ? 'small' : 'large'}
                  type="submit"
                  fullWidth
                >
                  {t('common:register')}
                </Button>
              </div>
            </Grid>
            {!session.getMandatoryRegister() && (
              <Grid item xs={12}>
                <Button
                  className={classes.doItLaterButton}
                  onClick={() => onNext(() => true)}
                  disableElevation
                  disableFocusRipple
                  disableRipple
                >
                  <Typography variant="button" component="p" className={classes.doItLaterText}>
                    {t('doItLater')}
                  </Typography>
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      />
    </>
  );
};

RegisterAddressOneColumnForm.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(RegisterAddressOneColumnForm);
