/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'contexts';

import { withStyles } from '@material-ui/core';
import { useUserInfo } from '@lc/lib/Provider';
import api from 'api';
import { url } from 'helpers';

import RegisterEmailOneColumn from './oneColumnForm';
import RegisterEmailTwoColumns from './twoColumnsForm';
import styles from './index.style';

const RegisterEmail = ({ classes, onNext, onBack, twoColumns }) => {
  const smSize = window.innerWidth < 600;
  const { t } = useTranslation('feedbacks');
  const { userData } = useUserInfo();
  const [, setMessage] = useSnackbar();

  const [validateEmail, setValidateEmail] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [emailData, setEmailData] = useState({ email: userData?.user?.email || '' });

  const clearInterval = () => {
    const intervalId = window.setInterval(() => {}, 9999);
    for (let i = 1; i < intervalId; i++) window.clearInterval(i);
  };

  const getRedirectUrl = () => {
    const redirectTo = url.shouldRedirectToAnotherPage() || '/';
    if (!redirectTo.startsWith('/')) {
      const tempUrl = new URL(redirectTo);
      // removing in case the user is in pendency flow
      tempUrl.searchParams.delete('action');
      tempUrl.searchParams.delete('id');
      return tempUrl.toString();
    }
    return redirectTo;
  };

  const checkEmailStatus = async () => {
    const [result, error] = await api.getProfile(userData?.user?.id);
    if (!error) {
      if (result?.steps?.email) {
        clearInterval();
        onNext(() => true);
      }
    }
  };

  const handleSave = async emailParam => {
    clearInterval();
    let { email } = emailData;
    if (userData?.user?.email?.provider_name === 'local') {
      email = emailParam;
      setEmailData({ email });
    }

    setIsFetching(true);
    const [data, error] =
      userData?.user?.email === email
        ? await api.resendEmail({ 'redirect_to': getRedirectUrl() })
        : await api.updateEmail({ email, 'redirect_to': getRedirectUrl() });
    setIsFetching(false);
    if (error && !error.isHandled) {
      if (data.error.status === 409) {
        setMessage({ message: t('feedbacks:explainEmailAlreadyInUse'), variant: 'error' });
      }
    }
    if (!error) {
      if (smSize) {
        onNext(() => true);
      } else {
        setValidateEmail(true);
        setInterval(checkEmailStatus, 5000);
      }
    }
  };

  const sendAgain = async () => {
    setIsFetching(true);
    await api.resendEmail({ 'redirect_to': getRedirectUrl() });
    setIsFetching(false);
  };

  return twoColumns ? (
    <RegisterEmailTwoColumns
      classes={classes}
      onNext={onNext}
      onBack={onBack}
      emailData={emailData}
      sendAgain={sendAgain}
      setValidateEmail={setValidateEmail}
      isFetching={isFetching}
      validateEmail={validateEmail}
      handleSave={handleSave}
    />
  ) : (
    <RegisterEmailOneColumn
      classes={classes}
      onNext={onNext}
      emailData={emailData}
      sendAgain={sendAgain}
      setValidateEmail={setValidateEmail}
      isFetching={isFetching}
      validateEmail={validateEmail}
      handleSave={handleSave}
    />
  );
};

RegisterEmail.propTypes = {
  classes: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  twoColumns: PropTypes.bool,
};
RegisterEmail.defaultProps = {
  twoColumns: false,
};

export default withStyles(styles)(RegisterEmail);
