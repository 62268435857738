import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  withStyles,
  Typography,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import UploadDocument from 'components/UploadDocument';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button } from '@lc/legacy/src/Components';
import { session } from 'helpers';
import styles from './index.style';

const initialValues = {
  documentFront: '',
  documentBack: '',
};
const RegisterDocumentOneColumnForm = ({ classes, onNext, uploadDocument }) => {
  const { t } = useTranslation(['register', 'forms', 'common']);
  const xsSize = useMediaQuery(useTheme().breakpoints.down('xs'));

  const [values, setValues] = useState(initialValues);
  const [loadingFront, setLoadingFront] = useState(false);
  const [loadingBack, setLoadingBack] = useState(false);
  const [documentType, setDocumentType] = useState('cnh');

  const handleState = async payload => {
    const form = new FormData();
    const { field, value } = payload;

    if (field === 'document_front') {
      form.append('document_front', value);
      setLoadingFront(true);
    } else {
      form.append('document_back', value);
      setLoadingBack(true);
    }
    form.set('document_type', documentType);
    setValues({ ...values, documentType, [field]: value });
    await uploadDocument(form);
    setLoadingFront(false);
    setLoadingBack(false);
  };

  const validate = () => {
    if (!loadingFront && !loadingBack && values.document_front && values.document_back) {
      onNext(() => true);
    }
  };

  const showButton = () => {
    if (values.document_front || values.document_back) {
      return (
        <div className={classes.fullButton}>
          <Button
            loading={loadingFront || loadingBack}
            variant="contained"
            color="primary"
            size={xsSize ? 'small' : 'medium'}
            fullWidth
            onClick={validate}
          >
            {t('common:continue')}
          </Button>
        </div>
      );
    }
    return '';
  };

  return (
    <Grid>
      <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
        <Typography variant={xsSize ? 'body1' : 'h6'}>
          <strong>{t('uploadDocument')}</strong>
        </Typography>
        <Typography
          variant={xsSize ? 'button' : 'body1'}
          color="textPrimary"
          className={classes.subtitle}
        >
          {t('explainPhotoDocumentForValidation')}
        </Typography>
        <Grid item xs={12} className={classes.labels} style={{ textAlign: 'center' }}>
          <RadioGroup
            style={{ flexDirection: 'row', justifyContent: 'space-around' }}
            name="documentType"
            value={values.document_type}
            onChange={e => setValues({ ...values, documentType: e.target.value })}
          >
            <FormControlLabel
              value="rg"
              checked={documentType === 'rg'}
              onChange={() => setDocumentType('rg')}
              control={<Radio size={xsSize ? 'small' : 'medium'} color="primary" />}
              label={t('forms:RGField')}
            />
            <FormControlLabel
              value="cnh"
              checked={documentType === 'cnh'}
              onChange={() => setDocumentType('cnh')}
              control={<Radio size={xsSize ? 'small' : 'medium'} color="primary" />}
              label={t('forms:CNHField')}
            />
          </RadioGroup>
        </Grid>

        <Grid
          container
          spacing={1}
          style={{
            display: 'flex',
            flexDirection: 'row',
            textAlign: 'center',
            marginTop: '20px',
          }}
        >
          <Grid item xs={12} sm={6}>
            <Typography variant={xsSize ? 'button' : 'subtitle1'}>
              <strong>{t('forms:documentPhotoFront')}</strong>
            </Typography>
            <UploadDocument
              loading={loadingFront}
              fieldName="document_front"
              stateParentHandle={handleState}
              color="rgba(0, 49, 255, 0.6)"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={xsSize ? 'button' : 'subtitle1'}>
              <strong>{t('forms:documentPhotoBack')}</strong>
            </Typography>
            <UploadDocument
              loading={loadingBack}
              fieldName="document_back"
              stateParentHandle={handleState}
              color="rgba(0, 49, 255, 0.6)"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={0} justify="center">
        <div className={classes.buttonUpload}>{showButton()}</div>
        {!session.getMandatoryRegister() && (
          <Button
            className={classes.doItLaterButton}
            onClick={() => onNext(() => true)}
            disableElevation
            disableFocusRipple
            disableRipple
          >
            <Typography variant="button" component="p" className={classes.doItLaterText}>
              {t('doItLater')}
            </Typography>
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

RegisterDocumentOneColumnForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  uploadDocument: PropTypes.func.isRequired,
};

export default withStyles(styles)(RegisterDocumentOneColumnForm);
