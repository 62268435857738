const styles = theme => ({
  content: {
    minHeight: '300px',
  },
  formErrorMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'hsla(6, 83%, 50%, 1)',
  },
  formErrorIcon: {
    fontSize: 16,
    color: 'hsla(6, 83%, 50%, 1)',
  },
  phoneFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    margin: theme.spacing(3, 0, 0.5, 0),
  },
  callingCodeText: {
    marginRight: theme.spacing(1),
  },
  warningText: {
    textAlign: 'center',
    color: 'hsla(0, 0%, 47%, 1)',
    margin: theme.spacing(0, 0, 3, 0),
  },
  button: {
    fontSize: theme.typography.pxToRem(14),
  },
});

export default styles;
