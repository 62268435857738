import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Grid, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useForm } from 'react-hook-form';
import { Avatar } from '@lc/legacy/src/Components';
import FlowControl from 'components/FlowControl';
import { useSnackbar } from 'contexts';

import styles from './index.style';

const RegisterPhotoTwoColumnsForm = ({
  classes,
  onSubmit,
  onNext,
  onBack,
  previewAvatar,
  profileData,
}) => {
  const { t } = useTranslation('register');
  const [, setMessage] = useSnackbar();

  const { handleSubmit } = useForm();
  const [user, setUser] = useState({ avatar: '' });

  const validate = () => {
    if (profileData.avatar === null && user.avatar === null) {
      setMessage({ message: t('feedbacks:yourProfilePicRequired'), variant: 'error' });
      return;
    }

    onNext(() => onSubmit());
  };

  useEffect(() => {
    setUser({ avatar: profileData?.user?.avatar });
  }, [profileData?.user?.avatar]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classes.signup}
      encType="multipart/form-data"
    >
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h5">{t('addYourProfilePhoto')}</Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '16px' }}>
          <Typography variant="subtitle2">{t('explainImportanceYourProfilePhoto')}</Typography>
        </Grid>
        <Grid container spacing={1} alignItems="center" style={{ marginTop: '40px' }}>
          <Grid item xs={12} sm={4}>
            <Avatar src={user.avatar} alt={t('yourProfilePic')} type="large" />
            <input
              id="file-input"
              accept="image/*"
              type="file"
              name="photo"
              onChange={() => previewAvatar(setUser)}
              style={{ display: 'none' }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              onClick={() => {
                document.querySelector('#file-input').click();
              }}
              variant="outlined"
              color="secondary"
              className={classes.signupButton}
              fullWidth
            >
              {t('changePhoto')}
            </Button>
          </Grid>
          <FlowControl
            isSubmit={false}
            onBack={() => onBack(() => true)}
            onNext={() => validate()}
          />
        </Grid>
      </Grid>
    </form>
  );
};

RegisterPhotoTwoColumnsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  previewAvatar: PropTypes.func.isRequired,
  profileData: PropTypes.object.isRequired,
};

export default withStyles(styles)(RegisterPhotoTwoColumnsForm);
