import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { useForm } from 'react-hook-form';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Avatar, Button } from '@lc/legacy/src/Components';
import styles from './index.style';

const RegisterPhotoOneColumnForm = ({
  classes,
  onSubmit,
  previewAvatar,
  profileData,
  isFetching,
}) => {
  const { t } = useTranslation(['register', 'common']);
  const xsSize = useMediaQuery(useTheme().breakpoints.down('xs'));

  const { handleSubmit } = useForm();
  const [user, setUser] = useState({ avatar: '' });

  useEffect(() => {
    if (profileData) setUser({ avatar: profileData?.user?.avatar });
  }, [profileData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant={xsSize ? 'body1' : 'h6'} component="h3" align="center">
            <strong>{t('thisIsYourProfilePhoto')}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={xsSize ? 'body2' : 'body1'} color="textPrimary" align="center">
            {t('explainProfilePhoto')}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ margin: '25px' }}>
          <Avatar src={user.avatar} alt={t('yourProfilePic')} type={xsSize ? 'medium' : 'large'} />
          <input
            id="file-input"
            accept="image/*"
            type="file"
            name="photo"
            onChange={() => previewAvatar(setUser)}
            style={{ display: 'none' }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            loading={isFetching}
            variant="contained"
            color="primary"
            size={xsSize ? 'small' : 'large'}
            className={classes.signupButton}
            fullWidth
            type="submit"
          >
            {t('common:continue')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={() => {
              document.querySelector('#file-input').click();
            }}
            variant="outlined"
            color="primary"
            size={xsSize ? 'small' : 'large'}
            fullWidth
          >
            {t('changePhoto')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

RegisterPhotoOneColumnForm.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  previewAvatar: PropTypes.func.isRequired,
  profileData: PropTypes.object.isRequired,
};

export default withStyles(styles)(RegisterPhotoOneColumnForm);
