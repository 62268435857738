import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'contexts';
import { withStyles, Grid, Typography } from '@material-ui/core';
import { session } from 'helpers';

import FlowControl from 'components/FlowControl';
import EmailForm from './emailForm';
import EmailValidate from './emailValidate';
import styles from '../index.style';

const RegisterEmailTwoColumns = ({
  classes,
  onNext,
  onBack,
  emailData,
  sendAgain,
  setValidateEmail,
  isFetching,
  validateEmail,
  handleSave,
}) => {
  const userData = session.getUserData();
  const { t } = useTranslation(['register', 'forms', 'common', 'feedbacks']);
  const [, setMessage] = useSnackbar();

  const [email, changeEmail] = useState((emailData && emailData.email) || '');
  const [error, setError] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);

  const submitEmail = async () => {
    if (email === '') {
      setError(true);
      return;
    }
    await handleSave(email);
    setSentEmail(true);
  };

  const validate = () => {
    if (userData && userData.steps.email) {
      onNext(() => true);
      return;
    }
    if (!sentEmail) {
      setMessage({ message: t('feedbacks:validateYourEmail'), variant: 'error' });
    }
    onNext(() => sentEmail);
  };
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h5">{t('confirmYourEmail')}</Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '16px' }}>
          <Typography variant="subtitle2">{t('explainEmailImportance')}</Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '16px' }}>
          <Typography variant="body1">{t('thisIsTheSameEmailYouUseToLogIn')}</Typography>
        </Grid>
      </Grid>
      {validateEmail && (
        <EmailValidate
          onNext={onNext}
          classes={classes}
          emailData={emailData}
          handleChange={() => setValidateEmail(false)}
          sendAgain={sendAgain}
          isFetching={isFetching}
        />
      )}
      {!validateEmail && (
        <EmailForm
          email={email}
          submitEmail={submitEmail}
          changeEmail={changeEmail}
          error={error}
          setError={setError}
          onNext={onNext}
        />
      )}
      <FlowControl isSubmit={false} onBack={() => onBack(() => true)} onNext={() => validate()} />
    </>
  );
};

RegisterEmailTwoColumns.propTypes = {
  classes: PropTypes.object.isRequired,
  emailData: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  sendAgain: PropTypes.func.isRequired,
  setValidateEmail: PropTypes.func.isRequired,
  validateEmail: PropTypes.bool.isRequired,
};

export default withStyles(styles)(RegisterEmailTwoColumns);
