import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles, Typography, Button, Fade, Zoom, CircularProgress } from '@material-ui/core';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import clsx from 'clsx';

const FetchingContainer = ({ classes, fetching, error, onPressTryAgain, children }) => {
  const { t } = useTranslation(['feedbacks', 'common']);

  const showErrorState = !fetching && error;
  const showContent = !fetching && !error;

  return (
    <>
      <Fade in={fetching}>
        <div className={clsx(classes.container, classes.fetching, !fetching && classes.hide)}>
          <CircularProgress color="secondary" />
        </div>
      </Fade>
      <Zoom in={showErrorState}>
        <div className={clsx(classes.container, !showErrorState && classes.hide)}>
          <SentimentVeryDissatisfiedIcon className={classes.icon} />
          <Typography variant="h4" className={classes.textMargin}>
            {t('feedbacks:servicesError')}
          </Typography>
          <Button variant="contained" color="secondary" onClick={onPressTryAgain}>
            {t('common:tryAgain')}
          </Button>
        </div>
      </Zoom>
      <Fade in={showContent}>
        <div className={clsx(!showContent && classes.hide)}>{children}</div>
      </Fade>
    </>
  );
};

const styles = theme => ({
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  fetching: {
    minHeight: theme.spacing(38),
  },
  hide: {
    display: 'none',
  },
  icon: {
    width: '150px',
    height: '150px',
    color: '#A6A6A6',
    margin: theme.spacing(0, 0, 0, 0),
  },
  textMargin: {
    margin: theme.spacing(4.5, 0),
  },
});

FetchingContainer.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  error: PropTypes.bool,
  fetching: PropTypes.bool,
  onPressTryAgain: PropTypes.func,
};
FetchingContainer.defaultProps = {
  error: false,
  fetching: false,
  onPressTryAgain: () => {},
};

export default withStyles(styles)(FetchingContainer);
