import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/styles';
import { useSnackbar } from 'contexts';

import api from 'api';
import { session } from 'helpers';
import AddressOneColumnForm from './addressOneColumnForm';
import AddressTwoColumnsForm from './addressTwoColumnsForm';
import styles from './index.style';

const RegisterAddress = ({ twoColumns, onNext, onBack }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [, setMessage] = useSnackbar();
  const { t } = useTranslation('feedbacks');
  const userData = session.getUserData();
  let addressData;
  if (userData && userData.address) {
    addressData = { ...userData.address, addressType: userData.address.address_type };
  }
  const onSubmit = async data => {
    setIsFetching(true);
    const [, error] = await api.saveAddress({
      'zipcode': data.zipcode,
      'street': data.street,
      'neighborhood': data.neighborhood,
      'city': data.city,
      'state': data.state,
      'number': data.number,
      'complement': data.complement,
      'country': data.country,
      'address_type': data.commercial || data.residential,
    });
    setIsFetching(false);
    if (error) {
      setMessage({ message: t('feedbacks:servicesError'), variant: 'error' });
    }
    if (!error) onNext(() => true);
  };

  return twoColumns ? (
    <AddressTwoColumnsForm
      isFetching={isFetching}
      onSubmit={onSubmit}
      onNext={onNext}
      onBack={onBack}
      data={addressData}
    />
  ) : (
    <AddressOneColumnForm isFetching={isFetching} onSubmit={onSubmit} onNext={onNext} />
  );
};

RegisterAddress.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  twoColumns: PropTypes.bool,
};
RegisterAddress.defaultProps = {
  twoColumns: false,
};

export default withStyles(styles)(RegisterAddress);
