const styles = theme => ({
  content: {
    width: '100%',
  },
  text: {
    textAlign: 'center',
  },
  subtitle: {
    margin: theme.spacing(0),
    textAlign: 'center',
  },
  documentContainer: {
    width: '190px',
    height: '150px',
    // backgroundColor: '#E6E6E6',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    margin: theme.spacing(3.5, 0, 3.5, 0),
  },
  contentImagePreview: {
    position: 'absolute',
    width: '190px',
    height: '150px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    margin: theme.spacing(3.5, 0, 3.5, 0),
  },
  imagePreview: {
    width: '100%',
    height: '100%',
  },
  uploadChecked: {
    position: 'absolute',
    right: '10%',
    bottom: ' 15%',
    width: '45px',
    height: '45px',
    background: '#34BA92',
    borderRadius: '50%',
  },
  documentIcon: {
    fontSize: theme.typography.pxToRem(30),
    color: 'hsla(0, 0%, 65%, 1)',
  },
  phoneFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    margin: theme.spacing(3, 0, 0.5, 0),
  },
  warningText: {
    textAlign: 'center',
    color: 'hsla(0, 0%, 47%, 1)',
    margin: theme.spacing(0, 0, 3, 0),
  },
  fullButton: {
    margin: theme.spacing(3.5, 0, 0, 0),
  },
  buttonUpload: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    marginTop: theme.spacing(3),
  },
  doItLaterButton: {
    width: '100%',
    margin: theme.spacing(5, 0, 2, 0),
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  doItLaterText: {
    color: 'hsla(0, 0%, 65%, 1)',
    textAlign: 'center',
  },
  formErrorMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'hsla(6, 83%, 50%, 1)',
  },
  formErrorIcon: {
    fontSize: 16,
    color: 'hsla(6, 83%, 50%, 1)',
  },
  labels: {
    '& .MuiFormControlLabel-label': {
      fontSize: theme.typography.pxToRem(16),
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
  },
});

export default styles;
