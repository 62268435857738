/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { InputField, FormHelperText } from '@lc/legacy/src/Components';

import { useTranslation } from 'react-i18next';

import {
  Typography,
  withStyles,
  FormControl,
  FormLabel,
  RadioGroup,
  Grid,
  FormControlLabel,
  Radio,
  TextField,
  FormGroup,
  Zoom,
} from '@material-ui/core';
import FlowControl from 'components/FlowControl';

import { Autocomplete } from '@material-ui/lab';

import styles from './styles/index.style';

const HEALTH_PROFESSIONAL = {
  YES: 'YES',
  NO: 'NO',
};

const RegisterOccupationAreaTwoColumnsForm = ({
  classes,
  onNext,
  onBack,
  onSubmit,
  getData,
  data,
}) => {
  const { t } = useTranslation(['register', 'forms', 'feedbacks', 'common']);

  const { register, errors, handleSubmit, setError, clearError } = useForm();
  const [formValues, setFormValues] = useState(
    data || {
      'expertise_id': null,
      'specialties_id': [],
      'issuer_state': '',
      'record_number': '',
      'health_professional': HEALTH_PROFESSIONAL.YES,
      'secondary_role_id': null,
    },
  );

  let initProfessionalValues = {
    description: '',
    specialties: [],
  };
  let initSecondaryRole = {
    title: '',
    description: '',
  };
  if (data) {
    initProfessionalValues = {
      description: data.expertise_id ? data.expertise_id.description : '',
      specialties: data.specialties || [],
    };
    initSecondaryRole = {
      id: data?.secondary_role_id?.id,
      title: data?.secondary_role_id?.title,
      description: data?.secondary_role_id?.description,
    };
  }

  const [amIProfessional, setAmIProfessional] = useState(
    data?.health_professional
      ? data.health_professional === HEALTH_PROFESSIONAL.YES
      : HEALTH_PROFESSIONAL.NO,
  );
  const [state, setState] = useState([]);
  const [stateWorkaround, setStateWorkaround] = useState('');
  const [selectedIssuerState, setIssuerState] = useState(data ? data.issuer_state : []);
  const [selectedSpecialties, setSpecialties] = useState(data ? data.specialties : []);
  const [secondaryRoles, setSecondaryRoles] = useState([]);
  const [selectedSecondaryRole, setSelectedSecondaryRoles] = useState(initSecondaryRole);
  const [expertises, setExpertises] = useState([{ description: '', specialties: [] }]);
  const [selectedProfessional, setProfessional] = useState(initProfessionalValues);

  const handleAmIProfessional = (event, amIProfessionalValue) => {
    const health_professional = amIProfessionalValue;
    setAmIProfessional(!amIProfessional);
    setFormValues({
      ...formValues,
      health_professional,
    });
  };

  const handleProfessional = (event, professional) => {
    setSpecialties([]);
    if (professional) {
      const expertise_id = professional.id;
      if (!professional) {
        setProfessional({ description: '', specialties: [] });
        return true;
      }
      clearError('professional');

      setProfessional(professional);
      setFormValues({
        ...formValues,
        expertise_id,
      });
    } else {
      setProfessional(initProfessionalValues);
    }
  };

  const handleSpecialties = (event, speciality) => {
    if (speciality[0] === undefined) {
      setError('speciality');
    } else {
      clearError('speciality');
    }

    if (speciality.length > 0 && selectedSpecialties.indexOf(speciality[0]) < 0) {
      const selectedArray = [...selectedSpecialties];

      selectedArray.push(speciality[0]);
      setSpecialties(selectedArray);

      const specialties_id = selectedArray.map(el => el.id);

      setFormValues({
        ...formValues,
        specialties_id,
      });
    } else {
      setSpecialties(speciality);
      setFormValues({
        ...formValues,
        specialties_id: speciality.map(el => el.id),
      });
    }
  };

  const handleIssuerState = (event, issuerValue) => {
    if (issuerValue) {
      const issuer_state = issuerValue.sigla;
      setIssuerState(issuerValue);
      setStateWorkaround(issuerValue.nome);
      setFormValues({
        ...formValues,
        issuer_state,
      });
    }
  };

  const handleRecordNumber = event => {
    const record_number = event.target.value;

    setFormValues({
      ...formValues,
      record_number,
    });
  };

  const handleSecondaryRole = (event, roleValue) => {
    if (roleValue) {
      const secondary_role_id = roleValue.id;

      setSelectedSecondaryRoles(roleValue);
      setFormValues({
        ...formValues,
        secondary_role_id,
      });
    }
  };

  const validate = () => {
    if (selectedProfessional?.specialties.length > 0 && selectedSpecialties?.length === 0) {
      setError('speciality');
    } else {
      handleSubmit(() => onNext(() => onSubmit(formValues)))();
    }
  };

  useEffect(() => {
    getData({ setExpertises, setState, setSecondaryRoles });
  }, []);

  useEffect(() => {
    const found = expertises.find(item => item.description === selectedProfessional.description);
    if (found) setProfessional(found);
  }, [expertises]);

  useEffect(() => {
    const st = state.find(item => item.sigla === selectedIssuerState);

    if (st) setStateWorkaround(st.nome);
  }, [state]);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Typography variant="h5">{t('occupationArea')}</Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '16px' }}>
        <Typography variant="subtitle2">{t('explainOccupationArea')}</Typography>
      </Grid>

      <div className={classes.content} style={{ width: '406px' }}>
        <FormControl fullWidth component="fieldset" className={classes.healthProfessional}>
          <FormLabel component="legend" color="secondary" className={classes.formLabel}>
            {t('imAHealthcareProfessional')}
          </FormLabel>
          <RadioGroup
            aria-label="professional"
            name="professional"
            color="secondary"
            value={formValues.health_professional}
            onChange={handleAmIProfessional}
            row
          >
            <FormControlLabel
              value={HEALTH_PROFESSIONAL.YES}
              control={<Radio color="secondary" className={classes.radioStyle} />}
              label={t('forms:labelYes')}
            />
            <FormControlLabel
              value={HEALTH_PROFESSIONAL.NO}
              control={<Radio color="secondary" className={classes.radioStyle} />}
              label={t('forms:labelNo')}
            />
          </RadioGroup>
          <Zoom in={amIProfessional} style={{ display: !amIProfessional ? 'none' : 'inherit' }}>
            <FormGroup>
              <FormControl fullWidth>
                <Autocomplete
                  id="professional-autocomplete-box"
                  options={expertises}
                  onChange={handleProfessional}
                  getOptionLabel={option => (option ? option.description : '')}
                  value={selectedProfessional}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      color="secondary"
                      name="professional"
                      inputRef={register({ required: amIProfessional })}
                      label={t('forms:occupationField')}
                      error={!!errors.professional}
                    />
                  )}
                />
                <FormHelperText
                  type="small"
                  error={!!errors.professional}
                  message={t('feedbacks:pleaseSelectAnOccupation')}
                />
              </FormControl>
              {selectedProfessional?.specialties.length > 0 && (
                <FormControl className={classes.specialityContainer}>
                  <Autocomplete
                    id="speciality-autocomplete-box"
                    name="specialties"
                    onChange={handleSpecialties}
                    multiple
                    options={selectedProfessional?.specialties}
                    getOptionLabel={option => (option ? option.description : '')}
                    value={selectedSpecialties}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        color="secondary"
                        name="speciality"
                        // inputRef={register({ required: true })}
                        label={t('forms:occupationExpertiseField')}
                        error={!!errors.speciality}
                      />
                    )}
                  />
                  <FormHelperText
                    type="small"
                    error={!!errors.speciality}
                    message={t('feedbacks:pleaseSelectASpecialty')}
                  />
                  <Typography className="link">
                    {t('feedbacks:youCanChooseMoreThanOneSpecialty')}
                  </Typography>
                </FormControl>
              )}
              <FormControl>
                <InputField
                  color="secondary"
                  id="register-number"
                  name="record_number"
                  label={t('forms:workerRegisterNumber')}
                  onChange={handleRecordNumber}
                  value={formValues.record_number}
                  inputRef={register({ required: amIProfessional })}
                  fullWidth
                  error={!!errors.record_number}
                  helperText={t('feedbacks:pleaseTypeRegisterNumber')}
                />
              </FormControl>
              <Autocomplete
                id="issuer-state-box"
                options={state}
                getOptionSelected={() => true}
                getOptionLabel={option => (typeof option === 'string' ? option : option.nome)}
                onChange={handleIssuerState}
                value={{ sigla: selectedIssuerState, nome: stateWorkaround }}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    color="secondary"
                    name="issuer_state"
                    inputRef={register({ required: amIProfessional })}
                    label={t('forms:workerRegisterState')}
                    error={!!errors.issuer_state}
                  />
                )}
              />
              <FormHelperText
                type="small"
                error={!!errors.issuer_state}
                message={t('feedbacks:pleaseSelectAIssuingState')}
              />
            </FormGroup>
          </Zoom>
          <Zoom in={!amIProfessional} style={{ display: !amIProfessional ? 'inherit' : 'none' }}>
            <FormControl>
              <Autocomplete
                id="role-box"
                onChange={handleSecondaryRole}
                options={secondaryRoles}
                value={selectedSecondaryRole}
                getOptionLabel={option => (option ? option.title : '')}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    name="role"
                    color="secondary"
                    inputRef={register({ required: !amIProfessional })}
                    label={t('forms:functionField')}
                    error={!!errors.role}
                  />
                )}
              />
              <FormHelperText
                type="small"
                error={!!errors.role}
                message={t('feedbacks:pleaseSelectAFunction')}
              />

              <Typography paragraph className={classes.roleSubtitle}>
                {selectedSecondaryRole && selectedSecondaryRole.description}
              </Typography>
            </FormControl>
          </Zoom>
        </FormControl>
      </div>
      <FlowControl isSubmit={false} onBack={() => onBack(() => true)} onNext={() => validate()} />
    </Grid>
  );
};

RegisterOccupationAreaTwoColumnsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  getData: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

RegisterOccupationAreaTwoColumnsForm.defaultProps = {
  data: {
    'expertise_id': null,
    'specialties_id': [],
    'issuer_state': '',
    'record_number': '',
    'health_professional': HEALTH_PROFESSIONAL.YES,
    'secondary_role_id': null,
  },
};

export default withStyles(styles)(RegisterOccupationAreaTwoColumnsForm);
