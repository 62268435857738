import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import { useUserInfo } from '@lc/lib/Provider';

import api from 'api';
import { useSnackbar } from 'contexts';

import { fixRotationOfFile } from 'helpers';

import RegisterPhotoOneColumnForm from './photoOneColumnForm';
import RegisterPhotoTwoColumnsForm from './photoTwoColumnsForm';
import styles from './index.style';

const RegisterPhoto = ({ twoColumns, onNext, onBack }) => {
  const { t } = useTranslation(['feedbacks']);
  const { userData, updateUserData } = useUserInfo();
  const [, setMessage] = useSnackbar();

  const [isFetching, setIsFetching] = useState(false);

  const onSubmit = async () => {
    const input = document.querySelector('#file-input');
    if (input.files && input.files[0]) {
      setIsFetching(true);
      const file = await fixRotationOfFile(input.files[0]);

      const [, errorSavePhoto] = await api.savePhotoProfile(file);
      const [data, errorGetProfileData] = await api.getProfileData();

      if (!errorGetProfileData) {
        updateUserData(data);
      }
      setIsFetching(false);
      onNext(() => !errorSavePhoto && !errorGetProfileData);
    } else {
      onNext(() => true);
    }
  };

  const previewAvatar = async setUser => {
    const input = document.querySelector('#file-input');
    const reader = new FileReader();
    if (input.files && input.files[0]) {
      const file = await fixRotationOfFile(input.files[0]);
      if (!file) {
        setMessage({ message: t('feedbacks:invalidImage'), variant: 'error' });
        return;
      }
      reader.onload = e => {
        setUser({ avatar: e.target.result });
      };
      reader.readAsDataURL(file);
    } else {
      setUser({ avatar: userData?.user?.avatar });
    }
  };

  return twoColumns ? (
    <RegisterPhotoTwoColumnsForm
      onSubmit={onSubmit}
      previewAvatar={previewAvatar}
      profileData={userData}
      onNext={onNext}
      onBack={onBack}
      isFetching={isFetching}
    />
  ) : (
    <RegisterPhotoOneColumnForm
      onSubmit={onSubmit}
      previewAvatar={previewAvatar}
      profileData={userData}
      isFetching={isFetching}
    />
  );
};

RegisterPhoto.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  twoColumns: PropTypes.bool,
};
RegisterPhoto.defaultProps = {
  twoColumns: false,
};

export default withStyles(styles)(RegisterPhoto);
