/* eslint-disable jsx-a11y/anchor-is-valid */
// @todo: get user location from api
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { InputField, Button, FormHelperText } from '@lc/legacy/src/Components';
import { Typography, Link, withStyles } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Smartphone } from '@material-ui/icons';
import { session } from 'helpers';
import styles from './oneColumn.style';

const PhoneValidate = ({
  classes,
  onNext,
  handleValidateCode,
  handleChangeNumber,
  phoneData,
  sendAgain,
  isFetching,
  invalidCode,
}) => {
  const { t } = useTranslation(['register', 'feedbacks', 'forms']);
  const xsSize = useMediaQuery(useTheme().breakpoints.down('xs'));

  return (
    <>
      <Typography
        variant={xsSize ? 'body1' : 'h6'}
        component="h3"
        align="center"
        className={classes.title}
      >
        <strong>{t('insert4digitsCode')}</strong>
      </Typography>
      <Typography
        paragraph
        variant={xsSize ? 'button' : 'body2'}
        color="textSecondary"
        className={classes.subtitle}
        dangerouslySetInnerHTML={{
          __html: t('explainSentCodeToNumber', {
            number: `+${phoneData.selectedCountryCallingCode} ${phoneData.phoneNumber}`,
          }),
        }}
      />

      {!xsSize && (
        <div className={classes.phoneIconContainer}>
          <Smartphone className={classes.phoneIcon} />
        </div>
      )}
      <div className={classes.content}>
        <div className={classes.phoneFieldContainer}>
          <InputField
            placeholder={t('forms:verificationCodeField')}
            name="code"
            inputProps={{ style: { textAlign: 'center' }, maxLength: 4 }}
            onChange={handleValidateCode}
            disabled={isFetching}
            // error={!errors.code}
          />
          {/* {isFetching && <CircularProgress size={16} color="primary" />} */}
        </div>
        <FormHelperText error={invalidCode} type="small" message={t('feedbacks:invalidCode')} />
        <div className={classes.links}>
          <Link href="#" onClick={handleChangeNumber}>
            <Typography color="primary" variant="button">
              {t('changeMyNumber')}
            </Typography>
          </Link>
        </div>
        <Button
          loading={isFetching}
          variant="contained"
          color="primary"
          size={xsSize ? 'small' : 'large'}
          className={classes.fullButton}
          onClick={sendAgain}
          fullWidth
        >
          {t('sendCodeAgain')}
        </Button>
        {!session.getMandatoryRegister() && (
          <Button
            className={classes.doItLaterButton}
            onClick={() => onNext(() => true)}
            disableElevation
            disableFocusRipple
            disableRipple
          >
            <Typography variant="button" component="p" className={classes.doItLaterText}>
              {t('doItLater')}
            </Typography>
          </Button>
        )}
      </div>
    </>
  );
};

PhoneValidate.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChangeNumber: PropTypes.func.isRequired,
  handleValidateCode: PropTypes.func.isRequired,
  invalidCode: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
  phoneData: PropTypes.object,
  sendAgain: PropTypes.func.isRequired,
};
PhoneValidate.defaultProps = {
  phoneData: undefined,
};

export default withStyles(styles)(PhoneValidate);
