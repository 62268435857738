const styles = theme => ({
  joinTitle: {
    paddingBottom: theme.spacing(2),
  },

  signupField: {
    margin: theme.spacing(1, 0),
  },
  signupButton: {
    textTransform: 'capitalize',
    // margin: theme.spacing(3, 0),
    // padding: theme.spacing(2, 0),
  },
});

export default styles;
