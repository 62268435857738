import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Typography, Grid, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { FormHelperText } from '@lc/legacy/src/Components';

import UploadDocument from 'components/UploadDocument';
import FlowControl from 'components/FlowControl';

import styles from './index.style';

const RegisterDocumentTwoColumnsForm = ({ onNext, onBack, data, uploadDocument }) => {
  const { t } = useTranslation(['register', 'feedbacks', 'forms']);
  const [values, setValues] = useState(data);
  const [error, setError] = useState(false);
  const [loadingFront, setLoadingFront] = useState(false);
  const [loadingBack, setLoadingBack] = useState(false);
  const [documentType, setDocumentType] = useState(data.document_type || 'cnh');

  const handleState = async payload => {
    setError(false);
    const form = new FormData();
    const { field, value } = payload;

    if (field === 'document_front') {
      form.append('document_front', value);
      setLoadingFront(true);
    } else {
      form.append('document_back', value);
      setLoadingBack(true);
    }
    form.set('document_type', documentType);
    setValues({ ...values, documentType, [field]: value });
    await uploadDocument(form);
    setLoadingFront(false);
    setLoadingBack(false);
  };

  const validate = () => {
    if (!loadingFront && !loadingBack && values.document_front && values.document_back) {
      onNext(() => true);
    }
    setError(true);
  };

  return (
    <>
      <Grid container direction="row" spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h5">{t('identificationDocument')}</Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '16px' }}>
          <Typography variant="subtitle2">{t('explainPhotoDocumentForValidation')}</Typography>
        </Grid>
        <Grid container spacing={0} alignItems="center" style={{ marginTop: '40px' }}>
          <RadioGroup
            style={{ flexDirection: 'row', justifyContent: 'space-around' }}
            name="documentType"
            value={values.document_type}
            onChange={e => setValues({ ...values, documentType: e.target.value })}
          >
            <FormControlLabel
              value="rg"
              checked={documentType === 'rg'}
              onChange={() => setDocumentType('rg')}
              control={<Radio classes={{ root: styles.radio }} />}
              label="RG"
            />
            <FormControlLabel
              style={{ marginLeft: '58px' }}
              value="cnh"
              checked={documentType === 'cnh'}
              onChange={() => setDocumentType('cnh')}
              control={<Radio classes={{ root: styles.radio }} />}
              label="CNH"
            />
          </RadioGroup>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">{t('forms:documentPhotoFront')}</Typography>
          <UploadDocument
            loading={loadingFront}
            theme="secondary"
            url={data.document_front}
            fieldName="document_front"
            stateParentHandle={handleState}
            color="#34BA92"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">{t('forms:documentPhotoBack')}</Typography>
          <UploadDocument
            loading={loadingBack}
            theme="secondary"
            url={data.document_back}
            fieldName="document_back"
            stateParentHandle={handleState}
            color="#34BA92"
          />
        </Grid>
      </Grid>
      {error && (
        <FormHelperText error type="small" message={t('feedbacks:pleaseChooseFrontBackDocument')} />
      )}
      <Grid container spacing={0} style={{ marginTop: '16px' }}>
        <Grid item>
          <Typography variant="body1">{t('register:explainDocumentForValidation')}</Typography>
        </Grid>
      </Grid>
      <FlowControl isSubmit={false} onBack={() => onBack(() => true)} onNext={() => validate()} />
    </>
  );
};

RegisterDocumentTwoColumnsForm.propTypes = {
  data: PropTypes.object,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  uploadDocument: PropTypes.func.isRequired,
};
RegisterDocumentTwoColumnsForm.defaultProps = {
  data: {
    'document_front': '',
    'document_back': '',
    'document_type': 'cnh',
  },
};

export default RegisterDocumentTwoColumnsForm;
