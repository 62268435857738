// @todo: get user location from api
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { InputField, Button } from '@lc/legacy/src/Components';
import { TextField, FormControl, Typography, FormHelperText, withStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Smartphone, Close } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { masks, session } from 'helpers';
import countriesCodes from 'helpers/countries_code.json';
import styles from './oneColumn.style';

const defaultCountryCode = countriesCodes.find(i => i.fone === '0055');

const PhoneForm = ({ classes, onNext, handleSavePhoneNumber, phoneData, isFetching }) => {
  const { t } = useTranslation(['register', 'forms', 'feedbacks', 'common']);
  const xsSize = useMediaQuery(useTheme().breakpoints.down('xs'));

  const [selectedCountryCallingCode, changeCallingCode] = useState(
    (phoneData && phoneData.selectedCountryCallingCode) || '0055',
  );
  const [phoneNumber, changePhoneNumber] = useState((phoneData && phoneData.phoneNumber) || '');
  const { register, handleSubmit, errors } = useForm();

  const handleChangeCountry = (event, country) => {
    const selectedCountry = country ? country.fone : undefined;

    changeCallingCode(selectedCountry);
  };

  const handleChangePhoneNumber = event => {
    // @todo: change mask if phone number has 8 digits - ex. (xx) xxxx-xxxx
    changePhoneNumber(event.target.rawValue);
  };

  return (
    <>
      <Typography
        variant={xsSize ? 'body1' : 'h6'}
        component="h3"
        align="center"
        className={classes.title}
      >
        <strong>{t('confirmYourPhoneNumber')}</strong>
      </Typography>
      <Typography
        variant={xsSize ? 'button' : 'body2'}
        color="textSecondary"
        className={classes.subtitle}
      >
        {t('explainPhoneNumberImportance')}
      </Typography>
      {!xsSize && (
        <div className={classes.phoneIconContainer}>
          <Smartphone className={classes.phoneIcon} />
        </div>
      )}
      <div className={classes.content}>
        <FormControl fullWidth>
          <Autocomplete
            id="country-autocomplete-box"
            options={countriesCodes}
            getOptionLabel={country => country.nome}
            onChange={handleChangeCountry}
            aria-describedby="country-helper-text"
            defaultValue={
              countriesCodes.find(
                item => Number(item.fone) === Number(selectedCountryCallingCode),
              ) || defaultCountryCode
            }
            renderInput={params => (
              <TextField
                {...params}
                name="country"
                value={selectedCountryCallingCode}
                inputRef={register({ required: true })}
                label={t('forms:countryField')}
                fullWidth
                error={!!errors.country}
              />
            )}
          />
          {!!errors.country && (
            <FormHelperText id="country-helper-text" className={classes.formErrorMessageContainer}>
              <Close className={classes.formErrorIcon} />
              {t('feedbacks:requiredField')}
            </FormHelperText>
          )}
        </FormControl>
        <div className={classes.phoneFieldContainer}>
          {selectedCountryCallingCode && (
            <Typography variant="body1" component="p" className={classes.callingCodeText}>
              {`+${Number(selectedCountryCallingCode)}`}
            </Typography>
          )}
          <FormControl fullWidth>
            <InputField
              withMask={[0, 55].includes(Number(selectedCountryCallingCode))}
              value={phoneNumber}
              placeholder={t('phoneNumber')}
              name="phoneNumber"
              inputProps={{ options: masks.phoneNineDigits }}
              onChange={handleChangePhoneNumber}
              inputRef={register({ required: true })}
              aria-describedby="phoneNumber-helper-text"
            />
            {!!errors.phoneNumber && (
              <FormHelperText
                id="phoneNumber-helper-text"
                className={classes.formErrorMessageContainer}
              >
                <Close className={classes.formErrorIcon} />
                {t('feedbacks:requiredField')}
              </FormHelperText>
            )}
          </FormControl>
        </div>
        <Typography
          variant={xsSize ? 'button' : 'body2'}
          color="textSecondary"
          component="p"
          className={classes.warningText}
        >
          {t('ratesMayApply')}
        </Typography>
        <Button
          loading={isFetching}
          variant="contained"
          color="primary"
          size={xsSize ? 'small' : 'large'}
          className={classes.fullButton}
          onClick={handleSubmit(() => {
            handleSavePhoneNumber({ selectedCountryCallingCode, phoneNumber });
          })}
          fullWidth
        >
          {t('common:register')}
        </Button>
        {!session.getMandatoryRegister() && (
          <Button
            className={classes.doItLaterButton}
            onClick={() => onNext(() => true)}
            disableElevation
            disableFocusRipple
            disableRipple
          >
            <Typography variant="button" component="p" className={classes.doItLaterText}>
              {t('doItLater')}
            </Typography>
          </Button>
        )}
      </div>
    </>
  );
};

PhoneForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSavePhoneNumber: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
  phoneData: PropTypes.object,
};

PhoneForm.defaultProps = {
  phoneData: undefined,
};

export default withStyles(styles)(PhoneForm);
