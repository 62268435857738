/* eslint-disable jsx-a11y/anchor-is-valid */
// @todo: get user location from api
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles, Typography, Link, CircularProgress } from '@material-ui/core';

import { useUserInfo } from '@lc/lib/Provider';

import styles from '../index.style';

const EmailValidate = ({ classes, handleChange, emailData, sendAgain, isFetching }) => {
  const { userData } = useUserInfo();

  const { t } = useTranslation(['register']);

  return (
    <div className={classes.validateField}>
      <Typography
        variant="subtitle2"
        color="textPrimary"
        className={classes.subtitle}
        dangerouslySetInnerHTML={{
          __html: t('explainSentEmail', { email: emailData && emailData.email }),
        }}
      />
      {isFetching && <CircularProgress />}
      <div className={classes.content}>
        <div className={classes.links}>
          <Link href="#" onClick={sendAgain}>
            <Typography color="secondary" variant="body1">
              {t('resendEmail')}
            </Typography>
          </Link>
          {userData?.user?.provider_name === 'local' && (
            <Link href="#" onClick={handleChange}>
              <Typography color="secondary" variant="body1">
                {t('changeEmail')}
              </Typography>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

EmailValidate.propTypes = {
  classes: PropTypes.object.isRequired,
  emailData: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  sendAgain: PropTypes.func.isRequired,
};
EmailValidate.defaultProps = {
  emailData: undefined,
};

export default withStyles(styles)(EmailValidate);
