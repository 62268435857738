// @todo: get user location from api
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { InputField, Button } from '@lc/legacy/src/Components';
import { withStyles, FormControl, Typography } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { useUserInfo } from '@lc/lib/Provider';

import { session } from 'helpers';

import styles from '../index.style';

const EmailForm = ({ classes, onNext, handleSave, emailData, isFetching }) => {
  const { t } = useTranslation(['register', 'forms', 'common']);
  const { userData } = useUserInfo();

  const xsSize = useMediaQuery(useTheme().breakpoints.down('xs'));

  const [email, changeEmail] = useState((emailData && emailData.email) || '');
  const [error, setError] = useState(false);
  const handleChange = event => {
    changeEmail(event.target.value);
    setError(false);
  };

  const submitEmail = () => {
    if (email === '') {
      setError(true);
      return;
    }
    handleSave(email);
  };

  return (
    <>
      <Typography
        variant={xsSize ? 'body1' : 'h6'}
        component="h3"
        align="center"
        className={classes.title}
      >
        <strong>{t('confirmYourEmail')}</strong>
      </Typography>
      <Typography
        paragraph
        variant={xsSize ? 'button' : 'body1'}
        color="textPrimary"
        className={classes.subtitle}
        style={{ textAlign: 'center' }}
      >
        {t('explainEmailImportance')}
      </Typography>
      <div className={classes.phoneIconContainer}>
        <MailOutlineIcon className={classes.phoneIcon} />
      </div>
      <div className={classes.content}>
        <div className={classes.phoneFieldContainer}>
          <FormControl fullWidth>
            <InputField
              value={email}
              placeholder={t('forms:emailField')}
              name="email"
              onChange={handleChange}
              error={error}
              readOnly={userData?.user?.provider_name !== 'local'}
            />
          </FormControl>
        </div>
        <div className={classes.fullButton}>
          <Button
            variant="contained"
            loading={isFetching}
            color="primary"
            size={xsSize ? 'small' : 'large'}
            onClick={submitEmail}
            fullWidth
          >
            {t('common:confirm')}
          </Button>
        </div>
        {!session.getMandatoryRegister() && (
          <Button
            className={classes.doItLaterButton}
            onClick={() => onNext(() => true)}
            disableElevation
            disableFocusRipple
            disableRipple
          >
            <Typography variant="button" component="p" className={classes.doItLaterText}>
              {t('doItLater')}
            </Typography>
          </Button>
        )}
      </div>
    </>
  );
};

EmailForm.propTypes = {
  classes: PropTypes.object.isRequired,
  emailData: PropTypes.object,
  handleSave: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
};

EmailForm.defaultProps = {
  emailData: undefined,
};

export default withStyles(styles)(EmailForm);
