import React from 'react';
import PropTypes from 'prop-types';

const HorizontalLine = ({ color, className }) => (
  <hr
    style={{ width: '100%', color, backgroundColor: color, height: '1px', border: 0 }}
    className={className}
  />
);

HorizontalLine.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

HorizontalLine.defaultProps = {
  color: '#000',
  className: undefined,
};

export default HorizontalLine;
