import React, { useState } from 'react';
import PropTypes from 'prop-types';
import api from 'api';
import { session, logger } from 'helpers';
import FetchingContainer from 'components/FetchingContainer';

import OccupationAreaOneColumnForm from './occupationAreaOneColumnForm';
import OccupationAreaTwoColumnsForm from './occupationAreaTwoColumnsForm';

const RegisterOccupationArea = ({ onNext, onBack, twoColumns }) => {
  const userData = session.getUserData();

  const [isFetching, setIsFetching] = useState(false);
  const [isInitFetch, setIsInitFetch] = useState(false);
  const [isInitFetchError, setIsInitFetchError] = useState(false);

  const refreshError = f => f();

  const getData = async ({ setExpertises, setState, setSecondaryRoles }) => {
    setIsInitFetchError(false);
    setIsInitFetch(true);
    const data = await Promise.all([
      api.getCustomExpertises(),
      api.getAllStatesBr(),
      api.getSecondaryRoles(),
    ]).catch(e => {
      setIsInitFetchError(true);
      logger.log('/features/Register/occupationArea/index.js', 'error', e);
    });
    const error = data.some(item => item[1]);
    if (!error) {
      const [expertises, states, secondaryRoles] = data.map(item => item[0]);
      setExpertises(expertises);
      setState(states.sort((a, b) => a.nome.localeCompare(b.nome)));
      setSecondaryRoles(secondaryRoles);
    } else {
      setIsInitFetchError(true);
    }
    setIsInitFetch(false);
  };

  const onSubmit = async data => {
    setIsFetching(true);

    const experId = data.expertise_id?.id ? data.expertise_id.id : data.expertise_id;

    const [response, error] =
      userData && userData.occupation && userData.occupation.id
        ? await api.updateOccupationArea(userData.occupation.id, {
            'expertise_id': experId,
            'specialties_id': data.specialties_id,
            'issuer_state': data.issuer_state,
            'record_number': data.record_number,
            'health_professional': data?.health_professional,
            'secondary_role_id': data?.secondary_role_id,
          })
        : await api.saveOccupationArea(data);
    setIsFetching(false);
    if (error && !response.isHandled) {
      // console.log('erro deve ser tratado na tela', response)
    }

    if (!error) {
      return true;
    }
    return false;
  };

  return twoColumns ? (
    <OccupationAreaTwoColumnsForm
      isFetching={isFetching}
      onNext={onNext}
      onBack={onBack}
      onSubmit={onSubmit}
      data={userData && userData.occupation}
      getData={getData}
    />
  ) : (
    <FetchingContainer
      fetching={isInitFetch}
      error={isInitFetchError}
      onPressTryAgain={refreshError}
    >
      <OccupationAreaOneColumnForm
        isFetching={isFetching}
        onNext={onNext}
        onSubmit={onSubmit}
        getData={getData}
        initFetch={v => refreshError(v)}
      />
    </FetchingContainer>
  );
};

RegisterOccupationArea.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  twoColumns: PropTypes.bool,
};
RegisterOccupationArea.defaultProps = {
  twoColumns: false,
};

export default RegisterOccupationArea;
