const styles = theme => ({
  uploadWrap: {
    position: 'relative',
    height: '150px',
    border: '1px dashed #797979',
    display: 'flex',
  },
  uploadWrapItem: {
    height: '90px',
    margin: 'auto',
  },
  uploadWrapItemText: {
    display: 'flex',
    justifyContent: 'center',
  },
  uploadWrapItemTextP: {
    width: '60%',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
      lineHeight: '16px',
      width: '90%',
    },
  },
  uploadWrapItemIcon: {
    display: 'flex',
    fontSize: '10px',
    fontWeight: '600',
    textAlign: 'center',
    flexDirection: 'column',
  },
  itemIcon: {
    width: 'auto',
    color: 'hsla(0, 0%, 65%, 1)',
  },
  inputFile: {
    position: 'absolute',
    zIndex: 90,
    left: '0',
    top: '0',
    opacity: '0',
    width: '100%',
    height: '100%',
  },
  imgPreview: {
    width: '190px',
    height: '150px',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  loading: {
    backgroundColor: '#ffffffbf',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 100,
  },
});

export default styles;
