/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { InputField, FormHelperText, Button } from '@lc/legacy/src/Components';

import {
  Typography,
  withStyles,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  FormGroup,
  Zoom,
} from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Autocomplete } from '@material-ui/lab';
import styles from './styles/index.style';

const HEALTH_PROFESSIONAL = {
  YES: 'YES',
  NO: 'NO',
};

const RegisterOccupationAreaOneColumnForm = ({
  classes,
  onNext,
  isFetching,
  onSubmit,
  getData,
  initFetch,
}) => {
  const { t } = useTranslation(['register', 'forms', 'feedbacks', 'common']);
  const xsSize = useMediaQuery(useTheme().breakpoints.down('xs'));

  const { register, errors, handleSubmit, setError, clearError } = useForm();

  const [formValues, setFormValues] = useState({
    'expertise_id': null,
    'specialties_id': [],
    'issuer_state': '',
    'record_number': '',
    'health_professional': HEALTH_PROFESSIONAL.YES,
    'secondary_role_id': null,
  });

  const initProfessionalValues = { description: '', specialties: [] };

  const [amIProfessional, setAmIProfessional] = useState(true);
  const [state, setState] = useState([]);
  const [selectedIssuerState, setIssuerState] = useState([]);
  const [selectedSpecialties, setSpecialties] = useState([]);
  const [secondaryRoles, setSecondaryRoles] = useState([]);
  const [selectedSecondaryRole, setSelectedSecondaryRoles] = useState([]);
  const [expertises, setExpertises] = useState([{ description: '', specialties: [] }]);
  const [selectedProfessional, setProfessional] = useState(initProfessionalValues);

  const handleAmIProfessional = (event, amIProfessionalValue) => {
    const health_professional = amIProfessionalValue;
    setAmIProfessional(!amIProfessional);
    setFormValues({
      ...formValues,
      health_professional,
    });
  };

  const handleProfessional = (event, professional) => {
    if (professional) {
      const expertise_id = professional.id;
      if (!professional) {
        setProfessional({ description: '', specialties: [] });
        return true;
      }
      clearError('professional');

      setProfessional(professional);
      setFormValues({
        ...formValues,
        expertise_id,
      });
    } else {
      setProfessional(initProfessionalValues);
    }
  };

  const handleSpecialties = (event, speciality) => {
    if (speciality[0] === undefined) {
      setError('speciality');
    } else {
      clearError('speciality');
    }

    if (speciality.length > 0 && selectedSpecialties.indexOf(speciality[0]) < 0) {
      const selectedArray = [...selectedSpecialties];

      selectedArray.push(speciality[0]);
      setSpecialties(selectedArray);

      const specialties_id = selectedArray.map(el => el.id);

      setFormValues({
        ...formValues,
        specialties_id,
      });
    } else {
      setSpecialties(speciality);
      setFormValues({
        ...formValues,
        specialties_id: speciality.map(el => el.id),
      });
    }
  };

  const handleIssuerState = (event, issuerValue) => {
    if (issuerValue) {
      const issuer_state = issuerValue.sigla;
      setIssuerState(issuerValue);
      setFormValues({
        ...formValues,
        issuer_state,
      });
    }
  };

  const handleRecordNumber = event => {
    const record_number = event.target.value;

    setFormValues({
      ...formValues,
      record_number,
    });
  };

  const handleSecondaryRole = (event, roleValue) => {
    if (roleValue) {
      const secondary_role_id = roleValue.id;

      setSelectedSecondaryRoles(roleValue);
      setFormValues({
        ...formValues,
        secondary_role_id,
      });
    }
  };

  useEffect(() => {
    getData({ setExpertises, setState, setSecondaryRoles });
    initFetch(() => getData({ setExpertises, setState, setSecondaryRoles }));
  }, []);

  return (
    <>
      <Typography variant={xsSize ? 'body1' : 'h6'} component="h3" align="center">
        <strong>{t('YourOccupationArea')}</strong>
      </Typography>
      <Typography variant={xsSize ? 'body2' : 'body1'} color="textPrimary" align="center">
        {t('explainOccupationArea')}
      </Typography>
      <div className={classes.content}>
        <FormControl fullWidth component="fieldset" className={classes.healthProfessional}>
          <FormLabel component="legend">
            <Typography variant={xsSize ? 'body2' : 'body1'} color="textPrimary">
              {t('imAHealthcareProfessional')}
            </Typography>
          </FormLabel>
          <RadioGroup
            aria-label="professional"
            name="professional"
            defaultValue={HEALTH_PROFESSIONAL.YES}
            onChange={handleAmIProfessional}
            className={classes.labels}
            row
          >
            <FormControlLabel
              value={HEALTH_PROFESSIONAL.YES}
              control={
                <Radio
                  size={xsSize ? 'small' : 'medium'}
                  color="primary"
                  className={classes.radioStyle}
                />
              }
              label={t('forms:labelYes')}
            />
            <FormControlLabel
              value={HEALTH_PROFESSIONAL.NO}
              control={
                <Radio
                  size={xsSize ? 'small' : 'medium'}
                  color="primary"
                  className={classes.radioStyle}
                />
              }
              label={t('forms:labelNo')}
            />
          </RadioGroup>
          <Zoom in={amIProfessional} style={{ display: !amIProfessional ? 'none' : 'inherit' }}>
            <FormGroup>
              <FormControl fullWidth>
                <Autocomplete
                  id="professional-autocomplete-box"
                  options={expertises}
                  onChange={handleProfessional}
                  getOptionLabel={option => (option ? option.description : '')}
                  value={selectedProfessional}
                  renderInput={params => (
                    <TextField
                      {...params}
                      fullWidth
                      name="professional"
                      inputRef={register({ required: amIProfessional })}
                      label={t('forms:occupationField')}
                      error={!!errors.professional}
                    />
                  )}
                />
                <FormHelperText
                  type="small"
                  error={!!errors.professional}
                  message={t('feedbacks:pleaseSelectAnOccupation')}
                />
              </FormControl>
              {selectedProfessional?.specialties?.length > 0 && (
                <FormControl className={classes.specialityContainer}>
                  <Autocomplete
                    id="speciality-autocomplete-box"
                    name="specialties"
                    onChange={handleSpecialties}
                    multiple
                    options={selectedProfessional?.specialties}
                    getOptionLabel={option => (option ? option.description : '')}
                    value={selectedSpecialties}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        name="speciality"
                        // inputRef={register({ required: true })}
                        label={t('forms:occupationExpertiseField')}
                        error={!!errors.speciality}
                      />
                    )}
                  />
                  <FormHelperText
                    type="small"
                    error={!!errors.speciality}
                    message={t('feedbacks:pleaseSelectASpecialty')}
                  />
                  <Typography className="link">
                    {t('feedbacks:youCanChooseMoreThanOneSpecialty')}
                  </Typography>
                </FormControl>
              )}
              <FormControl>
                <InputField
                  id="register-number"
                  name="recordNumber"
                  label={t('forms:workerRegisterNumber')}
                  onChange={handleRecordNumber}
                  value={formValues.recordNumber}
                  inputRef={register({ required: amIProfessional })}
                  fullWidth
                  error={!!errors.recordNumber}
                  helperText={t('feedbacks:pleaseTypeRegisterNumber')}
                />
              </FormControl>
              <Autocomplete
                id="issuer-state-box"
                options={state}
                getOptionLabel={option => (!Array.isArray(option) ? option.nome : '')}
                onChange={handleIssuerState}
                value={selectedIssuerState}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    name="issuerState"
                    inputRef={register({ required: amIProfessional })}
                    label={t('forms:workerRegisterState')}
                    error={!!errors.issuerState}
                  />
                )}
              />
              <FormHelperText
                type="small"
                error={!!errors.issuerState}
                message={t('feedbacks:pleaseSelectAIssuingState')}
              />
            </FormGroup>
          </Zoom>
          <Zoom in={!amIProfessional} style={{ display: !amIProfessional ? 'inherit' : 'none' }}>
            <FormControl>
              <Autocomplete
                id="role-box"
                onChange={handleSecondaryRole}
                options={secondaryRoles}
                getOptionLabel={option => (option ? option.title : '')}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    name="role"
                    inputRef={register({ required: !amIProfessional })}
                    label={t('forms:functionField')}
                    error={!!errors.role}
                  />
                )}
              />
              <FormHelperText
                type="small"
                error={!!errors.role}
                message={t('feedbacks:pleaseSelectAFunction')}
              />

              <Typography paragraph className={classes.roleSubtitle}>
                {selectedSecondaryRole.description}
              </Typography>
            </FormControl>
          </Zoom>
          <div className={classes.fullButton}>
            <Button
              variant="contained"
              loading={isFetching}
              color="primary"
              size={xsSize ? 'small' : 'large'}
              onClick={async () => {
                if (
                  selectedProfessional?.specialties?.length > 0 &&
                  selectedSpecialties?.length === 0
                ) {
                  setError('speciality');
                } else {
                  handleSubmit(() => {
                    onNext(() => onSubmit(formValues));
                  })();
                }
              }}
              fullWidth
            >
              {t('common:save')}
            </Button>
          </div>
        </FormControl>
      </div>
    </>
  );
};

RegisterOccupationAreaOneColumnForm.propTypes = {
  classes: PropTypes.object.isRequired,
  getData: PropTypes.func.isRequired,
  initFetch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(RegisterOccupationAreaOneColumnForm);
