// @todo: get user location from api
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { InputField } from '@lc/legacy/src/Components';
import { withStyles, Grid, Button } from '@material-ui/core';
import { session } from 'helpers';
import styles from '../index.style';

const EmailForm = ({ email, changeEmail, submitEmail, error, setError }) => {
  const { t } = useTranslation(['register', 'forms']);
  const userData = session.getUserData();

  const handleChange = event => {
    changeEmail(event.target.value);
    setError(false);
  };

  return (
    <Grid container spacing={2} style={{ marginTop: '48px' }}>
      <Grid item xs={6}>
        <InputField
          value={email}
          color="secondary"
          label={t('forms:emailField')}
          placeholder={t('forms:emailField')}
          name="email"
          onChange={handleChange}
          error={error}
          inputProps={{
            disabled: userData?.user?.provider_name !== 'local' || userData?.steps?.email,
          }}
        />
      </Grid>
      {!userData?.steps?.email && (
        <Grid item xs={6}>
          <Button color="secondary" variant="outlined" onClick={submitEmail}>
            {t('confirmEmail')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

EmailForm.propTypes = {
  changeEmail: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  setError: PropTypes.func.isRequired,
  submitEmail: PropTypes.func.isRequired,
};

export default withStyles(styles)(EmailForm);
