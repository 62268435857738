import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import api from 'api';
import { logger, session } from 'helpers';
import { useSnackbar } from 'contexts';

import RegisterDocumentOneColumnForm from './documentOneColumnForm';
import RegisterDocumentTwoColumnsForm from './documentTwoColumnsForm';
import styles from './index.style';

const RegisterDocument = ({ twoColumns, onNext, onBack }) => {
  const { t } = useTranslation('feedbacks');

  const userData = session.getUserData();
  const [, setMessage] = useSnackbar();

  const [documents] = useState({
    'document_back': userData ? userData.document_back : undefined,
    'document_front': userData ? userData.document_front : undefined,
    'document_type': userData ? userData.document_type : undefined,
  });

  const uploadDocument = async formData => {
    const [errorUpload, error] = await api.saveDocuments(formData);
    if (error) {
      setMessage({ message: t('feedbacks:servicesError'), variant: 'error' });

      logger.logError('src/features/Register/document/index.js uploadDocument()', {
        error: errorUpload,
      });
      return false;
    }
    return true;
  };

  return twoColumns ? (
    <RegisterDocumentTwoColumnsForm
      data={documents}
      uploadDocument={uploadDocument}
      onBack={onBack}
      onNext={onNext}
    />
  ) : (
    <RegisterDocumentOneColumnForm uploadDocument={uploadDocument} onNext={onNext} />
  );
};

RegisterDocument.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  twoColumns: PropTypes.bool,
};
RegisterDocument.defaultProps = {
  twoColumns: false,
};

export default withStyles(styles)(RegisterDocument);
