const styles = theme => ({
  contentBox: {
    marginTop: theme.spacing(3),
    background: theme.palette.calendar.background,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(3, 3),
  },
  link: {
    textDecoration: `underline ${theme.palette.secondary.main}`,
    '&:hover': {
      textDecoration: `underline ${theme.palette.secondary.main}`,
    },
  },
});

export default styles;
