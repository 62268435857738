import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import api from '@lc/lib/api';
import { logger } from 'helpers';

import { PhoneFormOneColumn, PhoneFormTwoColumns } from './form';
import { PhoneValidateOneColumn, PhoneValidateTwoColumns } from './validation';

const RegisterPhone = ({ onBack, onNext, twoColumns }) => {
  const [validatePhone, setValidatePhone] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const [phoneData, setPhoneData] = useState(undefined);
  const [currentData, setCurrentData] = useState(undefined);
  const [show, setShow] = useState(false);

  const getPhone = useCallback(async () => {
    const { data, hasError } = await api.phone.getPhone();

    if (!hasError) {
      const d = {
        selectedCountryCallingCode: String(Number(data.country_code)),
        phoneNumber: data.number,
        validated: data.validated,
        id: data.id,
      };
      setPhoneData(d);
      setCurrentData(d);
    }
    setShow(true);
  }, []);

  const handleSavePhoneNumber = async ({ selectedCountryCallingCode, phoneNumber }) => {
    if (
      currentData?.validated &&
      phoneNumber === currentData.phoneNumber &&
      selectedCountryCallingCode === currentData.selectedCountryCallingCode
    ) {
      onNext(() => true);
      return;
    }

    const requestPayload = {
      country_code: String(Number(selectedCountryCallingCode)),
      number: phoneNumber,
      'phone_type': 'private',
    };
    setIsFetching(true);
    const { data, hasError } =
      phoneData === undefined
        ? await api.phone.savePhone(requestPayload)
        : await api.phone.updatePhone(phoneData.id, requestPayload);
    setIsFetching(false);
    if (!hasError) {
      setPhoneData({
        phoneNumber,
        selectedCountryCallingCode: String(Number(selectedCountryCallingCode)),
        id: data.id,
      });
      setValidatePhone(true);
    } else {
      logger.logError(
        `features/Register/phone/index.js ${phoneData ? 'savePhoneNumber' : 'updatePhoneNumber'}()`,
        { error: data },
      );
    }
  };

  const handleValidateCode = async e => {
    setInvalidCode(false);
    const code = e.target.value;
    if (code.length === 4) {
      setIsFetching(true);
      const { data, hasError } = await api.phone.activePhoneNumber(phoneData.id, {
        'validation_code': code,
      });
      setIsFetching(false);
      if (!hasError) {
        onNext(() => true);
      } else {
        logger.logError('features/Register/phone/index.js activePhoneNumber()', { error: data });

        setInvalidCode(true);
      }
    }
  };

  const sendAgain = async () => {
    setIsFetching(true);

    const { data, hasError } = await api.phone.resendSmsPhoneNumber(phoneData.id);
    setIsFetching(false);
    if (hasError) {
      logger.logError('features/Register/phone/index.js resendSmsPhoneNumber()', { error: data });
    }
  };

  useEffect(() => {
    if (twoColumns) {
      getPhone();
    } else {
      setShow(true);
    }
  }, [getPhone, twoColumns]);

  const ValidateComponent = twoColumns ? PhoneValidateTwoColumns : PhoneValidateOneColumn;
  const FormComponent = twoColumns ? PhoneFormTwoColumns : PhoneFormOneColumn;

  return (
    <>
      {validatePhone && (
        <ValidateComponent
          onNext={onNext}
          phoneData={phoneData}
          handleChangeNumber={() => setValidatePhone(false)}
          handleValidateCode={handleValidateCode}
          sendAgain={sendAgain}
          isFetching={isFetching}
          invalidCode={invalidCode}
        />
      )}
      {!validatePhone && show && (
        <FormComponent
          onBack={onBack}
          onNext={onNext}
          phoneData={phoneData}
          isFetching={isFetching}
          handleSavePhoneNumber={handleSavePhoneNumber}
        />
      )}
    </>
  );
};

RegisterPhone.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  twoColumns: PropTypes.bool,
};

RegisterPhone.defaultProps = {
  twoColumns: false,
};

export default RegisterPhone;
