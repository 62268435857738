import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';

import EmailForm from './emailForm';
import EmailValidate from './emailValidate';
import styles from '../index.style';

const RegisterEmailOneColumn = ({
  classes,
  onNext,
  emailData,
  sendAgain,
  setValidateEmail,
  isFetching,
  validateEmail,
  handleSave,
}) => (
    <>
      {validateEmail && (
        <EmailValidate
          onNext={onNext}
          classes={classes}
          emailData={emailData}
          handleChange={() => setValidateEmail(false)}
          sendAgain={sendAgain}
          isFetching={isFetching}
        />
      )}
      {!validateEmail && (
        <EmailForm
          emailData={emailData}
          onNext={onNext}
          classes={classes}
          handleSave={handleSave}
          isFetching={isFetching}
        />
      )}
    </>
  );

RegisterEmailOneColumn.propTypes = {
  classes: PropTypes.object.isRequired,
  emailData: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
  sendAgain: PropTypes.func.isRequired,
  setValidateEmail: PropTypes.func.isRequired,
  validateEmail: PropTypes.bool.isRequired,
};

export default withStyles(styles)(RegisterEmailOneColumn);
