import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import { ArrowBackIosOutlined as ArrowBackIcon } from '@material-ui/icons';
import HorizontalLine from 'components/HorizontalLine';
import { withStyles } from '@material-ui/styles';

// eslint-disable-next-line
const FlowControl = ({ classes, onBack, onNext, isSubmit, loading, fixed, nextButtonText }) => {
  const { t } = useTranslation('common');

  const nextText = nextButtonText || t('next');

  return (
    <Grid container direction="column" className={classes.wrapper}>
      <HorizontalLine color="#34BA92" className={classes.horizontalSeparator} />
      <div className={classes.buttonsContainer}>
        {onBack && (
          <Button
            color="secondary"
            disableFocusRipple
            className={classes.button}
            startIcon={<ArrowBackIcon style={{ fontSize: 12 }} />}
            onClick={onBack}
          >
            {t('back')}
          </Button>
        )}

        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={() => !isSubmit && onNext()}
          disabled={loading}
          type={isSubmit ? 'submit' : 'button'}
        >
          {loading ? <CircularProgress className={classes.circularLoader} /> : nextText}
        </Button>
      </div>
    </Grid>
  );
};

FlowControl.propTypes = {
  classes: PropTypes.object.isRequired,
  fixed: PropTypes.bool,
  isSubmit: PropTypes.bool,
  loading: PropTypes.bool,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
};
FlowControl.defaultProps = {
  fixed: false,
  isSubmit: true,
  loading: false,
  onNext: undefined,
  onBack: undefined,
};

const styles = theme => ({
  wrapper: ({ fixed }) => ({
    background: fixed ? 'white' : 'unset',
    position: fixed ? 'sticky' : 'unset',
    bottom: fixed ? '0px' : 'unset',
    [theme.breakpoints.down('sm')]: {
      position: fixed ? 'fixed' : 'unset',
      bottom: fixed ? '63px' : 'unset',
      left: fixed ? '0px' : 'unset',
    },
  }),
  horizontalSeparator: props => ({
    margin: !props.fixed ? theme.spacing(9.3, 0, 4, 0) : theme.spacing(0, 0, 2, 0),
  }),
  buttonsContainer: props => {
    const justifyC = props.onBack === undefined ? 'flex-end' : 'space-between';

    return {
      display: 'flex',
      flex: 1,
      justifyContent: justifyC,
      alignItems: 'flex-end',
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 9, 2, 6),
      },
    };
  },
  button: {
    fontSize: theme.typography.pxToRem(16),
    minWidth: '115px',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12),
      maxHeight: '40px',
    },
  },
  circularLoader: {
    color: '#ffffff',
    maxWidth: '26px',
    maxHeight: '26px',
  },
});

export default withStyles(styles)(FlowControl);
