const styles = theme => ({
  content: {
    width: '100%',
    textAlign: 'center',
  },
  text: {
    textAlign: 'center',
  },
  title: {
    margin: theme.spacing(0, 0, 1, 0),
  },
  subtitle: {
    margin: theme.spacing(0),
    textAlign: 'center',
  },
  phoneIconContainer: {
    width: '140px',
    height: '140px',
    marginLeft: 'auto',
    marginRight: 'auto',
    border: `8px solid hsla(0, 0%, 65%, 1)`,
    borderRadius: theme.typography.pxToRem(140 / 2),
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    margin: theme.spacing(3.5, 0, 5.5, 0),
  },
  phoneIcon: {
    fontSize: theme.typography.pxToRem(80),
    color: 'hsla(0, 0%, 65%, 1)',
  },
  phoneFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    margin: theme.spacing(3, 0, 0.5, 0),
  },
  callingCodeText: {
    marginRight: theme.spacing(1),
  },
  warningText: {
    textAlign: 'center',
    color: 'hsla(0, 0%, 47%, 1)',
    margin: theme.spacing(0, 0, 3, 0),
  },
  fullButton: {
    textTransform: 'none',
  },
  doItLaterButton: {
    margin: theme.spacing(2, 0, 0, 0),
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  doItLaterText: {
    color: 'hsla(0, 0%, 65%, 1)',
    textAlign: 'center',
  },
  formErrorMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'hsla(6, 83%, 50%, 1)',
  },
  formErrorIcon: {
    fontSize: 16,
    color: 'hsla(6, 83%, 50%, 1)',
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    height: '50px',
    justifyContent: 'space-between',
    marginTop: '32px',
  },
});

export default styles;
