/* eslint-disable jsx-a11y/anchor-is-valid */
// @todo: get user location from api
import React from 'react';
import PropTypes from 'prop-types';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { withStyles, Typography, Link } from '@material-ui/core';
import { Button } from '@lc/legacy/src/Components';

import { useUserInfo } from '@lc/lib/Provider';

import styles from '../index.style';

const EmailValidate = ({ classes, handleChange, emailData, sendAgain, isFetching }) => {
  const xsSize = useMediaQuery(useTheme().breakpoints.down('xs'));
  const { userData } = useUserInfo();

  const { t } = useTranslation(['register']);

  return (
    <>
      <Typography
        variant={xsSize ? 'body1' : 'h6'}
        component="h3"
        className={classes.title}
        align="center"
      >
        <strong>{t('confirmYourEmail')}</strong>
      </Typography>
      <Typography
        paragraph
        variant={xsSize ? 'button' : 'body2'}
        color="textPrimary"
        align="center"
        className={classes.subtitle}
        dangerouslySetInnerHTML={{
          __html: t('explainSentEmail', { email: emailData && emailData.email }),
        }}
      />

      <div className={classes.content}>
        <div className={classes.links}>
          <Button
            loading={isFetching}
            variant="contained"
            color="primary"
            size={xsSize ? 'small' : 'large'}
            className={classes.fullButton}
            onClick={sendAgain}
            fullWidth
          >
            {t('resendEmail')}
          </Button>
          {userData?.user?.provider_name === 'local' && (
            <Link href="#" onClick={handleChange}>
              <Typography color="primary" variant="body2">
                {t('changeEmail')}
              </Typography>
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

EmailValidate.propTypes = {
  classes: PropTypes.object.isRequired,
  emailData: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  sendAgain: PropTypes.func.isRequired,
};
EmailValidate.defaultProps = {
  emailData: undefined,
};

export default withStyles(styles)(EmailValidate);
