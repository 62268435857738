const styles = theme => ({
  content: {
    width: '100%',
  },
  text: {
    textAlign: 'center',
  },
  subtitle: {
    margin: theme.spacing(0),
  },
  roleSubtitle: {
    color: '#383838',
    margin: theme.spacing(2, 0, 0, 0),
  },
  healthProfessional: {
    margin: theme.spacing(4, 0, 1, 0),
    paddingBottom: '60px',
  },
  radioStyle: {
    // color: 'hsla(228, 100%, 50%, 1)',
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
  specialityContainer: {
    width: '100%',
    maxWidth: 'calc(100vw - 64px)',
    '& .link': {
      textAlign: 'left',
      backgroundColor: 'transparent',
      border: 'none',
      padding: 0,
      margin: theme.spacing(0.8, 0, 2),
      color: 'hsla(195, 83%, 57%, 1)',
      fontSize: theme.typography.pxToRem(12),
      display: 'block',
    },
  },
  warningText: {
    textAlign: 'center',
    color: 'hsla(0, 0%, 47%, 1)',
    margin: theme.spacing(0, 0, 3, 0),
  },
  fullButton: {
    margin: theme.spacing(4, 0, 0, 0),
  },
  doItLaterButton: {
    margin: theme.spacing(2, 0, 0, 0),
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  doItLaterText: {
    color: 'hsla(0, 0%, 65%, 1)',
    textAlign: 'center',
  },
  formErrorMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'hsla(6, 83%, 50%, 1)',
  },
  formErrorIcon: {
    fontSize: 16,
    color: 'hsla(6, 83%, 50%, 1)',
  },
  labels: {
    '& .MuiFormControlLabel-label': {
      fontSize: theme.typography.pxToRem(16),
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
  },
});

export default styles;
