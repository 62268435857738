// @todo: get user location from api
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { InputField } from '@lc/legacy/src/Components';
import { Grid, TextField, Typography, Button, FormHelperText, withStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Close } from '@material-ui/icons';
import FlowControl from 'components/FlowControl';

import { masks } from 'helpers';
import countriesCodes from 'helpers/countries_code.json';
import styles from './twoColumns.style';

const defaultCountryCode = countriesCodes.find(i => i.fone === '0055');

const PhoneForm = ({ classes, onBack, handleSavePhoneNumber, phoneData }) => {
  const { t } = useTranslation(['register', 'forms', 'feedbacks', 'common']);
  const [selectedCountryCallingCode, changeCallingCode] = useState(
    (phoneData && phoneData.selectedCountryCallingCode) || '0055',
  );
  const [phoneNumber, changePhoneNumber] = useState((phoneData && phoneData.phoneNumber) || '');
  const { register, handleSubmit, errors } = useForm(['register', 'forms', 'feedbacks']);

  const handleChangeCountry = (event, country) => {
    const selectedCountry = country ? country.fone : undefined;
    changeCallingCode(selectedCountry);
  };

  const handleChangePhoneNumber = event => {
    const value = event.target.rawValue || event.target.value;
    changePhoneNumber(value);
  };

  const onSubmit = () => {
    handleSubmit(() => {
      handleSavePhoneNumber({ selectedCountryCallingCode, phoneNumber });
    })();
  };

  useEffect(() => {
    if (phoneData) {
      changePhoneNumber(phoneData.phoneNumber);
      changeCallingCode(phoneData.selectedCountryCallingCode);
    }
  }, [phoneData]);

  return (
    <Grid container spacing={2} justify="space-between">
      <Grid item xs={12}>
        <Typography variant="h5" component="h3">
          {t('cellphone')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography paragraph variant="subtitle2">
          {t('whyNumberPhoneIsImportant')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography paragraph variant="body1">
          {t('ratesMayApply')}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.content}>
        <Grid container direction="row" alignItems="flex-end" justify="space-between" spacing={4}>
          <Grid item xs={10} md={3}>
            <Autocomplete
              color="secondary"
              id="country-autocomplete-box"
              options={countriesCodes}
              getOptionLabel={country => country.nome}
              onChange={handleChangeCountry}
              defaultValue={
                countriesCodes.find(
                  item => Number(item.fone) === Number(selectedCountryCallingCode),
                ) || defaultCountryCode
              }
              renderInput={params => (
                <TextField
                  {...params}
                  name="country"
                  color="secondary"
                  value={selectedCountryCallingCode}
                  inputRef={register({ required: true })}
                  label={t('forms:countryField')}
                  fullWidth
                  error={!!errors.country}
                />
              )}
            />
          </Grid>
          <Grid item xs={10} md={4}>
            <Grid container spacing={0} direction="row" alignItems="flex-end">
              <Grid item>
                {selectedCountryCallingCode && (
                  <Typography variant="body1" component="p" className={classes.callingCodeText}>
                    {`+${Number(selectedCountryCallingCode)}`}
                  </Typography>
                )}
              </Grid>
              <Grid item xs>
                <InputField
                  withMask={[0, 55].includes(Number(selectedCountryCallingCode))}
                  color="secondary"
                  value={phoneNumber}
                  label={t('cellphone')}
                  name="phoneNumber"
                  inputProps={{ options: masks.phoneNineDigits }}
                  onChange={handleChangePhoneNumber}
                  inputRef={register({ required: true })}
                  aria-describedby="phoneNumber-helper-text"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10} md={3}>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              onClick={onSubmit}
            >
              {t('validateCellphone')}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={0} direction="row" alignItems="flex-end" justify="flex-start">
          <Grid item xs={5}>
            {!!errors.country && (
              <FormHelperText
                id="country-helper-text"
                className={classes.formErrorMessageContainer}
              >
                <Close className={classes.formErrorIcon} />
                {t('feedbacks:requiredField')}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={5}>
            {!!errors.phoneNumber && (
              <FormHelperText
                id="phoneNumber-helper-text"
                className={classes.formErrorMessageContainer}
              >
                <Close className={classes.formErrorIcon} />
                {t('feedbacks:requiredField')}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </Grid>

      <FlowControl isSubmit={false} onBack={() => onBack(() => true)} onNext={onSubmit} />
    </Grid>
  );
};

PhoneForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSavePhoneNumber: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  phoneData: PropTypes.object,
};

PhoneForm.defaultProps = {
  phoneData: undefined,
};

export default withStyles(styles)(PhoneForm);
