/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { InputField, FormHelperText } from '@lc/legacy/src/Components';
import { Grid, Typography, Link, CircularProgress, withStyles } from '@material-ui/core';

import FlowControl from 'components/FlowControl';

import styles from './twoColumns.style';

const PhoneValidate = ({
  classes,
  handleValidateCode,
  handleChangeNumber,
  phoneData,
  sendAgain,
  isFetching,
  invalidCode,
}) => {
  const { t } = useTranslation(['register', 'feedbacks', 'forms']);
  const [error, setError] = useState(false);
  const [validationCode, setValidationCode] = useState('');

  const handleCodeChange = event => {
    setError(false);
    setValidationCode(event.target.value);
    handleValidateCode(event);
  };

  const handleNext = () => {
    if (!validationCode || validationCode.length !== 4) {
      setError(true);
      return;
    }

    handleValidateCode({ target: { value: validationCode } });
  };

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12}>
        <Typography variant="h6" component="h3">
          {t('cellphone')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography paragraph variant="subtitle2">
          {t('whyNumberPhoneIsImportant')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography paragraph variant="body1">
          {t('ratesMayApply')}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <div className={classes.contentBox}>
          <Grid container spacing={8} direction="column" alignItems="center" justify="space-around">
            <Grid item xs={12}>
              <Typography
                align="center"
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: t('explainSentCodeToNumberVariation', {
                    number: `+${phoneData.selectedCountryCallingCode} ${phoneData.phoneNumber}`,
                  }),
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <Grid container direction="row" spacing={0}>
                <Grid item xs>
                  <InputField
                    placeholder="X X X X"
                    name="code"
                    color="secondary"
                    inputProps={{
                      style: { textAlign: 'center', letterSpacing: '10px' },
                      maxLength: 4,
                    }}
                    onChange={handleCodeChange}
                    disabled={isFetching}
                    error={error}
                    helperText={t('feedbacks:requiredField')}
                  />
                </Grid>
                <Grid item>{isFetching && <CircularProgress size={16} color="secondary" />}</Grid>
              </Grid>
              <Grid item>
                <FormHelperText
                  error={invalidCode}
                  type="small"
                  message={t('feedbacks:invalidCode')}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center" spacing={3}>
                <Grid item>
                  <Link href="#" onClick={sendAgain} className={classes.link}>
                    <Typography color="secondary" variant="body1">
                      {t('resendCode')}
                    </Typography>
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" onClick={handleChangeNumber}>
                    <Typography color="secondary" variant="body1" className={classes.link}>
                      {t('changeMyNumber')}
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>

      <FlowControl isSubmit={false} onBack={handleChangeNumber} onNext={handleNext} />
    </Grid>
  );
};

PhoneValidate.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChangeNumber: PropTypes.func.isRequired,
  handleValidateCode: PropTypes.func.isRequired,
  invalidCode: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  phoneData: PropTypes.object,
  sendAgain: PropTypes.func.isRequired,
};
PhoneValidate.defaultProps = {
  phoneData: undefined,
};

export default withStyles(styles)(PhoneValidate);
