/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { CloudUploadOutlined } from '@material-ui/icons';
import { withStyles, Typography } from '@material-ui/core';

import { useSnackbar } from 'contexts';
import { logger } from 'helpers';

import Spinner from 'components/Spinner';

import styles from './index.style';

const initialValues = {
  file: '',
  url: '',
};

const filesTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];

const UploadDocument = ({ classes, fieldName, url, stateParentHandle, color, theme, loading }) => {
  const { t } = useTranslation(['forms', 'feedbacks']);
  const [, setMessage] = useSnackbar();
  const [values, setValues] = useState(initialValues);

  const content = (
    <div className={classes.uploadWrapItem}>
      <div className={classes.uploadWrapItemIcon}>
        <CloudUploadOutlined className={classes.itemIcon} />
        <span>{t('documentPhotoType')}</span>
      </div>

      <div className={classes.uploadWrapItemText}>
        <Typography variant="button" color="textSecondary" className={classes.uploadWrapItemTextP}>
          <Trans i18nKey="explainHowToUploadDocument" ns="register">
            node one
            <a href="#" style={{ color }}>
              node two
            </a>
          </Trans>
        </Typography>
      </div>
    </div>
  );

  const preview = () => {
    let previewUrl = values.url || url;

    if (previewUrl.split('.pdf')[1] !== undefined) {
      previewUrl = '/images/icons/pdf.png';
    }

    return (
      <div
        id={fieldName}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url('${previewUrl}')`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />
    );
  };

  const render = () => (values.url || url ? preview() : content);

  const handleChange = async event => {
    const file = event.target.files[0];
    if (file === undefined) return;

    const fileType = file?.type?.toLowerCase();
    if (!filesTypes.includes(fileType)) {
      setMessage({ message: t('feedbacks:invalidFormat'), variant: 'error' });
      return;
    }

    setValues({ ...values, file });
    stateParentHandle({ field: fieldName, value: file });

    const reader = new FileReader();

    try {
      if (fileType === 'application/pdf') {
        setValues({ ...values, url: '/images/icons/pdf.png' });
        return;
      }

      reader.readAsDataURL(file);
      reader.onloadend = ({ currentTarget }) => {
        const base64data = currentTarget.result;
        setValues({ ...values, url: base64data });
      };
    } catch (error) {
      setMessage({ message: t('feedbacks:invalidImage'), variant: 'error' });
      logger.logError('components/UploadDocument/index.js handleChange', { error, file });
    }
  };

  return (
    <div className={classes.uploadWrap}>
      {loading && (
        <div className={classes.loading}>
          <Spinner color={theme} />
        </div>
      )}
      <input
        type="file"
        accept={filesTypes.join(',')}
        name="files"
        className={classes.inputFile}
        onChange={handleChange}
      />
      {render()}
    </div>
  );
};

UploadDocument.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  theme: PropTypes.string,
  stateParentHandle: PropTypes.func.isRequired,
  url: PropTypes.string,
};
UploadDocument.defaultProps = {
  url: undefined,
  theme: 'primary',
};

export default withStyles(styles)(UploadDocument);
